import React, { useRef, useCallback } from "react";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import SanityImage from "gatsby-plugin-sanity-image";
import BlockContent from "@sanity/block-content-to-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination } from "swiper";
import "./styles.scss";

type TestimonyCarouselProps = {
  _rawItems: {
    _key: string;
    author?: string;
    authorDescription: string;
    authorImage?: ImageInterface;
    text?: PortableText;
  }[];
};

export default function TestimonyCarousel(props: TestimonyCarouselProps) {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="testimonyCarousel">
      <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.957 29V14.9739C21.957 10.8039 23.389 6.34968 26.9212 3.69608C29.3079 1.89543 32.8401 0.852941 37.136 0L38.9499 6.63399C33.9857 7.48693 30.8353 10.5196 30.8353 14.9739H40V29H21.957ZM0 29V14.9739C0 10.8039 1.43198 6.34968 4.9642 3.69608C7.35083 1.89543 10.8831 0.852941 15.179 0L16.9928 6.63399C12.0286 7.48693 8.87828 10.5196 8.87828 14.9739H18.043V29H0Z"
          fill="white"
        />
      </svg>
      <div className="testimonyCarousel__container">
        {props._rawItems.map(item => (
          <div key={item._key} className="testimonyCarousel__card">
            <BlockContent blocks={item.text} />
            <div className="testimonyCarousel__card__author">
              {item.authorImage && (
                <SanityImage
                  {...item.authorImage}
                  width={72}
                  height={64}
                  htmlHeight={35}
                  className={`testimonyCarousel__card__author__image`}
                />
              )}
              <div>
                <p className="testimonyCarousel__card__author__name">{item.author}</p>
                <p className="testimonyCarousel__card__author__description">{item.authorDescription}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="testimonyCarousel__wrapper">
        <Swiper
          slidesPerView={1}
          modules={[Navigation, Pagination, A11y]}
          pagination={{ clickable: true }}
          className="testimonyCarousel__slider"
          ref={sliderRef}
        >
          {props._rawItems.map(item => (
            <SwiperSlide key={item._key}>
              <div className="testimonyCarousel__card">
                <BlockContent blocks={item.text} />
                <div className="testimonyCarousel__card__author">
                  {item.authorImage && (
                    <SanityImage
                      {...item.authorImage}
                      width={72}
                      height={64}
                      htmlHeight={64}
                      className={`testimonyCarousel__card__author__image`}
                    />
                  )}
                  <div>
                    <p className="testimonyCarousel__card__author__name">{item.author}</p>
                    <p className="testimonyCarousel__card__author__description">{item.authorDescription}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button type="button" className="navigation-button prev-arrow" onClick={handlePrev}>
          <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.939341 10.9393C0.353554 11.5251 0.353554 12.4749 0.939341 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939341 10.9393ZM22 10.5L2 10.5L2 13.5L22 13.5L22 10.5Z"
              fill="#FAEEDF"
            />
          </svg>
        </button>
        <button type="button" className="navigation-button next-arrow" onClick={handleNext}>
          <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.0607 13.0597C21.6464 12.4739 21.6464 11.5241 21.0607 10.9384L11.5147 1.39242C10.9289 0.806635 9.97918 0.806635 9.3934 1.39242C8.80761 1.97821 8.80761 2.92796 9.3934 3.51374L17.8787 11.999L9.3934 20.4843C8.80761 21.0701 8.80761 22.0198 9.3934 22.6056C9.97918 23.1914 10.9289 23.1914 11.5147 22.6056L21.0607 13.0597ZM-1.78873e-08 13.499L20 13.499L20 10.499L1.78873e-08 10.499L-1.78873e-08 13.499Z"
              fill="#FAEEDF"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
